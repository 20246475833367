import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '@mui/material/Alert';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { adminCreatePlayer } from '../APIs';

import { supabase } from '../auth/client';


function AdminBoardDialog(props) {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const [name, setName] = useState("")
    const [game, setGame] = useState("")
    const [team, setTeam] = useState("")
    const [opp, setOpp] = useState("")
    const [date, setDate] = useState("")
    const [maps, setMaps] = useState("")
    const [stat, setStat] = useState("")
    const [predicted, setPredicted] = useState(undefined)
    const [finished, setFinished] = useState(false)
    const [expired, setExpired] = useState(false)
    const [pro, setPro] = useState(true)
    const [color, setColor] = useState("")
    const [special, setSpecial] = useState('false')

    const [errorMsg, setErrorMsg] = useState({})

    const gameOptions = ['COD', 'HALO', 'WZ', 'CS2', 'VAL', 'APEX', 'LOL', 'RL', 'DOTA2', 'R6']

    const statOptions = ['KILLS', 'DEATHS', 'KD', 'KDA', 'HILL TIME', 'DMG', 'FIRST BLOODS', 'FIRST DEATHS', 'RATING', 'HEADSHOTS', 'ASSISTS', 'ADR', 'ACS', 'GOALS', 'SAVES', 'SHOTS', 'DEMOS', 'ELIMS']

    const specialOptions = ['false', 'DISCOUNTED']

    function isValidString(input, minLength, maxLength) {
        return typeof input === 'string' && input.length >= minLength && input.length <= maxLength;
    }
    
    function isValidGame(input) {
        return gameOptions.includes(input);
    }

    function isValidStat(input) {
        return statOptions.includes(input);
    }

    function isValidSpecial(input) {
        return specialOptions.includes(input);
    }
    
    function isValidMaps(input) {
        const regex = /^(\d+(-\d+)?)$/;
        return regex.test(input);
    }
    
    function isValidNumber(input) {
        const number = Number(input);
        return !isNaN(number) && number >= 0 && number <= 999999;
    }
    
    function isValidBoolean(input) {
        return typeof input === 'boolean';
    }

    function isValidColor(input) {
        const regex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
        return regex.test(input);
    }

    // Check if value is empty, null, undefined, or NaN
    function isValidValue(value) {
        return value !== null && value !== undefined && value !== '' && !Number.isNaN(value);
    }
    
    function verifyPlayerData(userData) {
    
        if (!isValidValue(userData.name) || !isValidString(userData.name, 2, 40)) {
            setErrorMsg({type: "name", msg: "Invalid name. Must be 2-40 characters long"});
            return false;
        }
        if (!isValidValue(userData.game) || !isValidGame(userData.game)) {
            setErrorMsg({type: "game", msg: "Invalid game"});
            return false;
        }
        if (!isValidValue(userData.team) || !isValidString(userData.team, 2, 20)) {
            setErrorMsg({type: "team", msg: "Invalid team. Must be 2-20 characters long"});
            return false;
        }
        if (!isValidValue(userData.img) || !isValidColor(userData.img)) {
            setErrorMsg({type: "color", msg: "Invalid color. Must be hexcode (ex. #A80000)"});
            return false;
        }
        if (!isValidValue(userData.opp) || !isValidString(userData.opp, 2, 20)) {
            setErrorMsg({type: "opp", msg: "Invalid opponent. Must be 2-20 characters long"});
            return false;
        }
        if (!isValidValue(userData.date)) {
            setErrorMsg({type: "date", msg: "Invalid date"});
            return false;
        }
        if (!isValidValue(userData.maps) || !isValidMaps(userData.maps)) {
            setErrorMsg({type: "maps", msg: "Invalid maps format. Must be formatted '#' or '#-#' "});
            return false;
        }
        if (!isValidValue(userData.stat) || !isValidStat(userData.stat)) {
            setErrorMsg({type: "stat", msg: "Invalid stat"});
            return false;
        }
        if (!isValidValue(userData.predicted) || !isValidNumber(userData.predicted)) {
            setErrorMsg({type: "predicted", msg: "Invalid predicted value. Must be 0-999999"});
            return false;
        }
        if (!isValidValue(userData.finished) || !isValidBoolean(userData.finished)) {
            setErrorMsg({type: "finished", msg: "Invalid finished value. Must be true or false"});
            return false;
        }
        if (!isValidValue(userData.expired) || !isValidBoolean(userData.expired)) {
            setErrorMsg({type: "expired", msg: "Invalid expired value. Must be true or false"});
            return false;
        }
        if (!isValidValue(userData.pro) || !isValidBoolean(userData.pro)) {
            setErrorMsg({type: "pro", msg: "Invalid pro value. Must be true or false"});
            return false;
        }
        if (!isValidValue(userData.special) || !isValidSpecial(userData.special)) {
            setErrorMsg({type: "special", msg: "Invalid special"});
            return false;
        }

        // If all checks pass, clear any previous error message
        setErrorMsg({});
        return true;
    }

    const handleSubmit = async () => {

        // check inputs

        const userData = {
            name: name,
            game: game,
            team: team,
            opp: opp,
            date: date,
            maps: maps,
            stat: stat,
            predicted: predicted,
            finished: finished,
            expired: expired,
            pro: pro,
            img: color,
            special: special,
            token: props.adminSession.access_token
        }

        if (!verifyPlayerData(userData)) {
            return
        } 

        const data = await adminCreatePlayer(userData)

        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } 
        else if (data.status !== 200) {
            setErrorMsg({type: "other", msg: "Server error"})
            return
        }

        props.setUpdate(!props.update)
        handleClose()
    }
    
    const handleClose = () => {
        props.setOpen(false)
        setErrorMsg({})
        setName("")
        setGame("")
        setTeam("")
        setOpp("")
        setDate("")
        setMaps("")
        setStat("")
        setPredicted(null)
        setFinished(false)
        setExpired(false)
        setPro(true)
        setColor("")
        setSpecial('false')
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handleGameChange = (event) => {
        setGame(event.target.value)
    }

    const handleTeamChange = (event) => {
        setTeam(event.target.value)
    }

    const handleOppChange = (event) => {
        setOpp(event.target.value)
    }

    const handleDateChange = (event) => {
        setDate(event.target.value)
    }

    const handleMapsChange = (event) => {
        setMaps(event.target.value)
    }

    const handleStatChange = (event) => {
        setStat(event.target.value)
    }

    const handleSpecialChange = (event) => {
        setSpecial(event.target.value)
    }

    const handlePredictedChange = (event) => {
        setPredicted(event.target.value)
    }

    const handleColorChange = (event) => {
        setColor(event.target.value)
    }

    const handleProChange = (event) => {
        setPro(event.target.value)
    }

    const handleFinishedChange = (event) => {
        setFinished(event.target.value)
    }

    const handleExpiredChange = (event) => {
        setExpired(event.target.value)
    }

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0 && errorMsg.type === "other") {
          const timer = setTimeout(() => {
            setErrorMsg({})
          }, 5000)

          return () => clearTimeout(timer);
        }
    }, [errorMsg])

    return (
        <ThemeProvider theme={darkTheme}>
            {errorMsg && errorMsg.msg && errorMsg.type === 'other' ? 
                <Alert sx={{ 
                    position: 'fixed', top: {xs: '60px', md: '100px'}, width: {xs:'80%', md:'auto'}, 
                    zIndex: 997, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
            <Dialog
                open={props.open}
                onClose={handleClose}
                sx={{ zIndex: 996 }}
            >
                <DialogTitle>Create New Player Bet</DialogTitle>
                <DialogContent sx={{ minWidth: '600px', boxSizing: 'border-box' }}>
                    <TextField
                        autoFocus
                        sx={{ mb: '10px' }}
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={handleNameChange}
                        error={errorMsg.type === "name" ? true : false}
                        helperText={errorMsg.type === "name" ? errorMsg.msg : false}
                    />

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '36px', boxSizing: 'border-box', alignItems: 'end' }}>
                        <FormControl sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', minWidth: '110px' }}>
                            <InputLabel id="game">Game</InputLabel>
                            <Select
                                value={game}
                                onChange={handleGameChange}
                                label="Game"
                                labelId="game"
                                id="game"
                                error={errorMsg.type === "game" ? true : false}
                            >
                                <MenuItem value={'COD'}>COD</MenuItem>
                                <MenuItem value={'HALO'}>HALO</MenuItem>
                                <MenuItem value={'WZ'}>WZ</MenuItem>
                                <MenuItem value={'CS2'}>CS2</MenuItem>
                                <MenuItem value={'VAL'}>VAL</MenuItem>
                                <MenuItem value={'APEX'}>APEX</MenuItem>
                                <MenuItem value={'LOL'}>LOL</MenuItem>
                                <MenuItem value={'RL'}>RL</MenuItem>
                                <MenuItem value={'DOTA2'}>DOTA2</MenuItem>
                                <MenuItem value={'R6'}>R6</MenuItem>
                            </Select>
                            {errorMsg.type === "game" && <FormHelperText error>{errorMsg.msg}</FormHelperText>}
                        </FormControl>

                        <TextField
                            sx={{ mb: '-1px' }}
                            margin="dense"
                            id="team"
                            name="team"
                            label="Team"
                            fullWidth
                            variant="outlined"
                            value={team}
                            onChange={handleTeamChange}
                            error={errorMsg.type === "team" ? true : false}
                            helperText={errorMsg.type === "team" ? errorMsg.msg : false}
                        />

                        <TextField
                            sx={{ mb: '-1px' }}
                            margin="dense"
                            id="color"
                            name="color"
                            label="Color"
                            fullWidth
                            variant="outlined"
                            value={color}
                            onChange={handleColorChange}
                            error={errorMsg.type === "color" ? true : false}
                            helperText={errorMsg.type === "color" ? errorMsg.msg : false}
                        />

                        {/*
                        <FormControl sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', minWidth: '110px' }}>
                            <InputLabel id="color">Color</InputLabel>
                            <Select
                                value={color}
                                onChange={handleColorChange}
                                label="color"
                                labelId="color"
                                id="color"
                                error={errorMsg.type === "color" ? true : false}
                            >
                                <MenuItem value={'KILLS'}>KILLS</MenuItem>
                                <MenuItem value={'DEATHS'}>DEATHS</MenuItem>
                                <MenuItem value={'KD'}>KD</MenuItem>
                            </Select>
                            {errorMsg.type === "color" && <FormHelperText error>{errorMsg.msg}</FormHelperText>}
                        </FormControl>
                        */}

                        <TextField
                            sx={{ mb: '-1px' }}
                            margin="dense"
                            id="opp"
                            name="opp"
                            label="Opp"
                            fullWidth
                            variant="outlined"
                            value={opp}
                            onChange={handleOppChange}
                            error={errorMsg.type === "opp" ? true : false}
                            helperText={errorMsg.type === "opp" ? errorMsg.msg : false}
                        />
                    </Box>

                    <InputLabel htmlFor="date" sx={{ mb: '-10px', mt: '10px' }} >Date/Time (EST)</InputLabel>
                    <TextField
                        sx={{ mb: '10px' }}
                        margin="dense"
                        id="date"
                        name="date"
                        type="datetime-local"
                        fullWidth
                        variant="outlined"
                        value={date}
                        onChange={handleDateChange}
                    />

                    <TextField
                        sx={{ mb: '10px' }}
                        margin="dense"
                        id="maps"
                        name="maps"
                        label="Maps"
                        fullWidth
                        variant="outlined"
                        value={maps}
                        onChange={handleMapsChange}
                        error={errorMsg.type === "maps" ? true : false}
                        helperText={errorMsg.type === "maps" ? errorMsg.msg : false}
                    />

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '36px', boxSizing: 'border-box', alignItems: 'end' }}>

                        <FormControl sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', minWidth: '130px' }}>
                            <InputLabel id="stat">Stat</InputLabel>
                            <Select
                                value={stat}
                                onChange={handleStatChange}
                                label="stat"
                                labelId="stat"
                                id="stat"
                                error={errorMsg.type === "stat" ? true : false}
                            >
                                <MenuItem value={'KILLS'}>KILLS</MenuItem>
                                <MenuItem value={'DEATHS'}>DEATHS</MenuItem>
                                <MenuItem value={'KD'}>KD</MenuItem>
                                <MenuItem value={'KDA'}>KDA</MenuItem>
                                <MenuItem value={'HILL TIME'}>HILL TIME</MenuItem>
                                <MenuItem value={'DMG'}>DMG</MenuItem>
                                <MenuItem value={'FIRST BLOODS'}>FIRST BLOODS</MenuItem>
                                <MenuItem value={'FIRST DEATHS'}>FIRST DEATHS</MenuItem>
                                <MenuItem value={'RATING'}>RATING</MenuItem>
                                <MenuItem value={'HEADSHOTS'}>HEADSHOTS</MenuItem>
                                <MenuItem value={'ASSISTS'}>ASSISTS</MenuItem>
                                <MenuItem value={'ADR'}>ADR</MenuItem>
                                <MenuItem value={'ACS'}>ACS</MenuItem>
                                <MenuItem value={'GOALS'}>GOALS</MenuItem>
                                <MenuItem value={'SAVES'}>SAVES</MenuItem>
                                <MenuItem value={'SHOTS'}>SHOTS</MenuItem>
                                <MenuItem value={'DEMOS'}>DEMOS</MenuItem>
                                <MenuItem value={'ELIMS'}>ELIMS</MenuItem>
                            </Select>
                            {errorMsg.type === "stat" && <FormHelperText error>{errorMsg.msg}</FormHelperText>}
                        </FormControl>

                        <TextField
                            sx={{ mb: '-1px' }}
                            margin="dense"
                            id="predicted"
                            name="predicted"
                            label="Predicted"
                            fullWidth
                            variant="outlined"
                            type="number"
                            value={predicted}
                            onChange={handlePredictedChange}
                            error={errorMsg.type === "predicted" ? true : false}
                            helperText={errorMsg.type === "predicted" ? errorMsg.msg : false}
                        />
                    </Box>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '36px', mt: '10px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <InputLabel htmlFor="pro">Pro</InputLabel>
                            <Select
                                value={pro}
                                onChange={handleProChange}
                                input={<OutlinedInput label="" />}
                                inputProps={{
                                    name: 'pro',
                                    id: 'pro',
                                }}
                            >
                                <MenuItem value={false}>false</MenuItem>
                                <MenuItem value={true}>true</MenuItem>
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <InputLabel htmlFor="expired">Expired</InputLabel>
                            <Select
                                value={expired}
                                onChange={handleExpiredChange}
                                input={<OutlinedInput label="" />}
                                inputProps={{
                                    name: 'expired',
                                    id: 'expired',
                                }}
                            >
                                <MenuItem value={false}>false</MenuItem>
                                <MenuItem value={true}>true</MenuItem>
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <InputLabel htmlFor="finished">Finished</InputLabel>
                            <Select
                                value={finished}
                                onChange={handleFinishedChange}
                                input={<OutlinedInput label="" />}
                                inputProps={{
                                    name: 'finished',
                                    id: 'finished',
                                }}
                            >
                                <MenuItem value={false}>false</MenuItem>
                                <MenuItem value={true}>true</MenuItem>
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', minWidth: '120px' }}>
                            <InputLabel htmlFor="special">Special</InputLabel>
                            <Select
                                value={special}
                                onChange={handleSpecialChange}
                                input={<OutlinedInput label="" />}
                                inputProps={{
                                    name: 'special',
                                    id: 'special',
                                }}
                            >
                                <MenuItem value={false}>false</MenuItem>
                                <MenuItem value={'DISCOUNTED'}>DISCOUNTED</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Create</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default AdminBoardDialog