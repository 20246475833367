import { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../auth/client';

import RedirectPage from '../pages/RedirectPage';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [linkedLogin, setLinkedLogin] = useState(undefined)

  useEffect(() => {
    /*
    supabase.auth.getSession().then(({ data: { session } }) => {
      console.log(`GETSESSION NEW: ${JSON.stringify(session)}`)
      setSession(session)
      // check for login with twitch/discord/twitter etc
      if (session?.provider_token) {
        console.log('linked login1')
        setLinkedLogin(true)
      } else {
        setLinkedLogin(false)
      }
      setLoading(false)
    })
    */


    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, newSession) => {
      if (_event !== "SIGNED_IN") {
        // console.log(`event: ${_event}`)

        // Only update if there is a real change
        setSession(prev => {
          if (JSON.stringify(prev) === JSON.stringify(newSession)) return prev;
          return newSession;
        });

        setLinkedLogin(!!newSession?.provider_token);
        setLoading(false);

      }
    })

    return () => subscription.unsubscribe()
  }, [])

  if (loading) return <div>Loading...</div>;

  return (
    <SessionContext.Provider value={{ session, setSession, linkedLogin, setLinkedLogin }}>
      {session === undefined ? <RedirectPage /> : children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
