import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import BarLoader from "react-spinners/BarLoader";

import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import AmountInput2 from './AmountInput2';
import { acceptRejection, createDotsUser, getDotsPayoutLink, getDotsVerified, getUserInfoForWithdraw, getVerificationCode, requestWithdraw, savePhone } from '../APIs'

import { supabase } from '../auth/client';

function WithdrawDialog(props) {


    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const navigate = useNavigate()

    const [phase, setPhase] = useState(null)

    const [first, setFirst] = useState("")
    const [last, setLast] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [winningsWalletBalance, setWinningsWalletBalance] = useState(0)
    const [dotsIsVerified, setDotsIsVerified] = useState(false)

    const [code, setCode] = useState("")
    // for requesting another verification code
    const [resentCode, setResentCode] = useState(false)

    const [amount, setAmount] = useState('')

    // 0 = nothing, 1 = waiting for admin, 2 = approved by admin, -1 = denied by admin
    const [waitingPhase, setWaitingPhase] = useState(0)
    const [link, setLink] = useState("")

    const [errorMsg, setErrorMsg] = useState({});
    const [isLoading, setIsLoading] = useState(true)

    const fetchInfo = async () => {
        setIsLoading(true)
        const response = await getUserInfoForWithdraw({token: props.session.access_token})
        
        if (response.status === 401) {
          props.setSession(null)
          const { error } = await supabase.auth.signOut()
          navigate('/login')
          return
        } else if (response.status !== 200) {
          setIsLoading(false)
          return
        }

        setFirst(response?.info?.firstName)
        setLast(response?.info?.lastName)
        setEmail(response?.info?.email)
        setPhone(response?.info?.phone)
        setDotsIsVerified(response?.info?.dotsIsVerified)

        setWaitingPhase(response?.info?.waitingPhase)
        setWinningsWalletBalance(parseFloat(response?.info?.winningsWalletBalance.$numberDecimal))

        if (phase !== 3 && response?.info?.dotsWalletID && !response?.info?.dotsIsVerified) {
            setPhase(1)
        } else if (phase !== 3 && response?.info?.dotsIsVerified) {
            setPhase(2)
        } else if (phase !== 3) (
            setPhase(0)
        )
    
        setIsLoading(false)
    
        return
    }

    const handleClose = () => {
        props.setOpen(false)
        setIsLoading(false)
    }

    const handleFirstChange = (event) => {
        setFirst(event.target.value.toUpperCase())
    }

    const handleLastChange = (event) => {
        setLast(event.target.value.toUpperCase())
    }

    const handleCodeChange = (event) => {
        const value = event.target.value
        const isValid = /^[0-9]{0,6}$/.test(value)
    
        if (isValid) {
            setCode(value)
        }
    }

    const isValidName = (name) => {
        const nameRegex = /^[A-Za-z\s-]{1,32}$/; // Allows letters, spaces, and hyphens, and checks length between 1 and 32
        return nameRegex.test(name);
    }

    const handleContinue = async () => {
        if (phase === 0) {

            setIsLoading(true)

            const userData = {
                phone: phone,
                token: props.session.access_token
            }

            // check if first and last are valid
            if (!isValidValue(first) || !isValidName(first) ) {
                setErrorMsg({type: "first", msg: "Invalid first name"})
                return
            }
            if (!isValidValue(last) || !isValidName(last)) {
                setErrorMsg({type: "last", msg: "Invalid last name"})
                return
            }

            // check if phone number is valid
            if (phone && (!isPossiblePhoneNumber(phone) || !isValidPhoneNumber(phone))) {
                setErrorMsg({type: "phone", msg: "Invalid phone number"})
                return
            }

            const response = await savePhone(userData)

            if (response.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response.status !== 200) {
                if (response?.errorObj) {
                    setErrorMsg(response?.errorObj)
                } else {
                    setErrorMsg({type: "other", msg: "Server Error 1. Please try again later or contact support."})
                }
                setIsLoading(false)
                return
            }

            const phoneData = parsePhoneNumber(phone)

            const userData2 = {
                first_name: first,
                last_name: last,
                country_code: phoneData?.countryCallingCode,
                phone_number: phoneData?.nationalNumber,
                email: email,
                token: props.session.access_token
            }

            const response2 = await createDotsUser(userData2)

            if (response2.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response2.status !== 200) {
                if (response2?.errorObj) {
                    setErrorMsg(response2?.errorObj)
                } else {
                    setErrorMsg({type: "other", msg: "Server Error 2. Please try again later or contact support."})
                }
                setIsLoading(false)
                return
            }

            setPhase(1)
            setIsLoading(false)
        }
    }

    const handleVerificationCodeResend = async () => {
        setResentCode(true)
        if (phase === 1 && waitingPhase === 0) {
            const userData = {
                token: props.session.access_token
            }
            const response = await getVerificationCode(userData)

            if (response.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response.status !== 200) {
                if (response?.errorObj) {
                    setErrorMsg(response?.errorObj)
                } else {
                    setErrorMsg({type: "other", msg: "Server Error 3. Please try again later or contact support."})
                }
                setIsLoading(false)
                return
            }

        }
    }

    function maskPhoneNumber(phone) {
        if (phone.length > 4) {
            return phone.slice(0, -4) + '****'
        }
        return phone
    }
    

    useEffect(() => {
        if (resentCode) {
          const timer = setTimeout(() => {
            setResentCode(false)
          }, 20000)
          
          return () => clearTimeout(timer);
        }
    }, [resentCode])

    const handleVerification = async () => {
        if (phase === 1 && waitingPhase === 0) {
            setIsLoading(true)
            const userData = {
                code: code,
                token: props.session.access_token
            }
            const response = await getDotsVerified(userData)

            if (response.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response.status !== 200) {
                if (response?.errorObj) {
                    setErrorMsg(response?.errorObj)
                } else {
                    setErrorMsg({type: "other", msg: "Server Error 4. Please try again later or contact support."})
                }
                setIsLoading(false)
                return
            }

            setDotsIsVerified(true)
            setPhase(2)
            setIsLoading(false)
        }
    }

    const handleWithdrawRequest = async () => {
        if (phase === 2 && waitingPhase === 0 && dotsIsVerified) {

            setIsLoading(true)

            if (isNaN(parseFloat(winningsWalletBalance))) {
                setErrorMsg({type: 'requesting', msg: 'Invalid withdrawal amount'})
                setIsLoading(false)
                return
            }

            if (isNaN(parseFloat(amount))) {
                setErrorMsg({type: 'requesting', msg: 'Invalid withdrawal amount'})
                setIsLoading(false)
                return
            }

            // check $10 withdraw minimum
            if (parseFloat(winningsWalletBalance) < 10) {
                setErrorMsg({type: 'requesting', msg: 'Minimum withdrawal amount is $10'})
                setIsLoading(false)
                return
            }

            // check $10 withdraw minimum
            if (parseFloat(amount) < 10) {
                setErrorMsg({type: 'requesting', msg: 'Minimum withdrawal amount is $10'})
                setIsLoading(false)
                return
            }

            // check withdraw max
            if (parseFloat(amount) > parseFloat(winningsWalletBalance)) {
                setErrorMsg({type: 'requesting', msg: `Maximum withdrawal amount is $${winningsWalletBalance.toFixed(2)}`})
                setIsLoading(false)
                return
            }

            const userData = {
                token: props.session.access_token,
                amount: amount
            }

            const response = await requestWithdraw(userData)

            if (response.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response.status !== 200) {
                if (response?.errorObj) {
                    setErrorMsg(response?.errorObj)
                } else {
                    setErrorMsg({type: "other", msg: "Server Error 5. Please try again later or contact support."})
                }
                setIsLoading(false)
                return
            }

            setWaitingPhase(response?.waitingPhase)
            setIsLoading(false)
            setAmount('')
            setErrorMsg({})
        }
    }

    const handleAdminResponse = async () => {
        if (waitingPhase === 2) {
            setIsLoading(true)
            const userData = {
                token: props.session.access_token
            }

            const response = await acceptRejection(userData)

            if (response.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response.status !== 200) {
                if (response?.errorObj) {
                    setErrorMsg(response?.errorObj)
                } else {
                    setErrorMsg({type: "other", msg: "Server Error 6. Please try again later or contact support."})
                }
                setIsLoading(false)
                return
            }

            setWaitingPhase(response?.waitingPhase)

            const response2 = await getDotsPayoutLink(userData)

            if (response2.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response2.status !== 200) {
                if (response2?.errorObj) {
                    setErrorMsg(response2?.errorObj)
                } else {
                    setErrorMsg({type: "other", msg: "Server Error 7. Please try again later or contact support."})
                }
                setIsLoading(false)
                return
            }

            setLink(response2?.link)
            setPhase(3)
            setIsLoading(false)
        } else {
            const userData = {
                token: props.session.access_token
            }
            const response = await acceptRejection(userData)

            if (response.status === 401) {
                props.setSession(null)
                const { error } = await supabase.auth.signOut()
                navigate('/login')
                return
            } else if (response.status !== 200) {
                if (response?.errorObj) {
                    setErrorMsg(response?.errorObj)
                } else {
                    setErrorMsg({type: "other", msg: "Server Error 8. Please try again later or contact support."})
                }
                setIsLoading(false)
                return
            }

            setWaitingPhase(response?.waitingPhase)
            setPhase(2)
        }
    }

    const handleWithdraw = async () => {
        setIsLoading(true)
        const userData = {
            token: props.session.access_token
        }
        const response = await getDotsPayoutLink(userData)

        if (response.status === 401) {
            props.setSession(null)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            if (response?.errorObj) {
                setErrorMsg(response?.errorObj)
            } else {
                setErrorMsg({type: "other", msg: "Server Error 9. Please try again later or contact support."})
            }
            setIsLoading(false)
            return
        }

        setLink(response.link)
        setPhase(3)
        setIsLoading(false)
    }

    // Check if value is empty, null, undefined, or NaN
    const isValidValue = (value) => {
        return value !== null && value !== undefined && value !== '' && !Number.isNaN(value);
    }

    useEffect(() => {
        if (props.session) {
          fetchInfo()
          setErrorMsg({})
        }
    }, [props.session])

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0) {
          const timer = setTimeout(() => {
            setErrorMsg({})
          }, 5000)

          return () => clearTimeout(timer);
        }
    }, [errorMsg])

    return (
        <ThemeProvider theme={darkTheme}>
            {false || (errorMsg && errorMsg.msg && errorMsg.type === 'other') ? 
                <Alert sx={{ 
                    position: 'fixed', top: {xs: '60px', md: '100px'}, width: {xs:'80%', md:'auto'}, 
                    zIndex: 997, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
            <Dialog
                open={props.open}
                onClose={handleClose}
                sx={{ zIndex: 996, mt: {xs:'42px', md:'72px'}, height: { xs: 'calc(100% - 42px)', md: 'calc(100% - 72px)' } }}
            >
                <DialogTitle sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: '24px',
                    color: 'white',
                    p: '16px',
                    alignSelf: 'center',
                    textShadow: '2px 2px 3px black',
                    letterSpacing: 0.6,
                    lineHeight: 1.1,
                    textAlign: 'center'
                }}>
                    WITHDRAW FUNDS
                </DialogTitle>
                {isLoading ?
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: {xs:'280px', sm: '600px'}, boxSizing: 'border-box', p: {xs:'16px', sm:'64px'}, justifyContent: 'center', alignItems: 'center', minHeight: {xs:'380px', sm: '380px'} }}>
                        <BarLoader
                            color="rgba(168, 0, 0, 1)"
                            loading="true"
                            height={6}
                            width={200}
                            data-testid="loader"
                        />
                    </DialogContent>
                :
                    <>
                        {phase === 0 ?
                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: {xs:'240px', sm: '500px'}, minHeight: {xs:'240px', sm: '500px'}, boxSizing: 'border-box', px: {xs:'16px', sm:'64px'}, gap: '12px' }}>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'18px', sm:'20px'}, color: 'white', alignSelf: 'center', lineHeight: 1.2 }}>
                                    First Time Wallet Setup
                                </Typography>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'12px', sm:'14px'}, color: 'white', alignSelf: 'center', textAlign: 'center', lineHeight: 1.2 }}>
                                    Please enter your information correctly, or else our payment partner cannot confirm your identity!
                                </Typography>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '6px', boxSizing: 'border-box' }}>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
                                        First Name
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: 2,
                                        borderColor: errorMsg?.type === 'first' ? '#d32f2f' : 'rgba(255, 255, 255, 0.2)',
                                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                                        borderRadius: '8px',
                                        textAlign: 'left',
                                        height: '52px',
                                        padding: '0 10px', 
                                        width: '100%',
                                        boxSizing: 'border-box'
                                    }}>
                                        <Input
                                            disableUnderline 
                                            fullWidth 
                                            value={first}
                                            onChange={handleFirstChange}
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                color: 'white',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: 'gray',
                                                },
                                            }} 
                                        />
                                    </Box>
                                    <Typography sx={{ color: '#d32f2f', fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: '15px', mb: '-15px', textAlign: 'center' }}>
                                        {errorMsg && errorMsg?.type === "first" ? errorMsg.msg : null }
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '6px', boxSizing: 'border-box' }}>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
                                        Last Name
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: 2,
                                        borderColor: errorMsg?.type === 'last' ? '#d32f2f' : 'rgba(255, 255, 255, 0.2)',
                                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                                        borderRadius: '8px',
                                        textAlign: 'left',
                                        height: '52px',
                                        padding: '0 10px', 
                                        width: '100%',
                                        boxSizing: 'border-box'
                                    }}>
                                        <Input
                                        disableUnderline 
                                        fullWidth 
                                        value={last}
                                        onChange={handleLastChange}
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            color: 'white',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: 'gray',
                                            },
                                        }} 
                                        />
                                    </Box>
                                    <Typography sx={{ color: '#d32f2f', fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: '15px', mb: '-15px', textAlign: 'center' }}>
                                        {errorMsg && errorMsg?.type === "last" ? errorMsg.msg : null }
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '6px', boxSizing: 'border-box' }}>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
                                        Email
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: 2,
                                        borderColor: 'rgba(255, 255, 255, 0.2)',
                                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                                        borderRadius: '8px',
                                        textAlign: 'left',
                                        height: '52px',
                                        padding: '0 10px', 
                                        width: '100%',
                                        boxSizing: 'border-box'
                                    }}>
                                        <Input
                                        disableUnderline 
                                        fullWidth 
                                        disabled
                                        value={email}
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            color: 'white',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: 'gray',
                                            },
                                        }} 
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '6px', boxSizing: 'border-box' }}>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
                                        Phone Number
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: 2,
                                        borderColor: errorMsg && errorMsg?.type === "phone" ? '#d32f2f' : 'rgba(255, 255, 255, 0.2)',
                                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                                        borderRadius: '8px',
                                        textAlign: 'left',
                                        height: '52px',
                                        padding: '0 10px', 
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}>
                                        <PhoneInput
                                            international
                                            defaultCountry="US"
                                            className="phone-input"
                                            value={phone}
                                            onChange={setPhone}
                                        />
                                    </Box>
                                    <Typography sx={{ color: '#d32f2f', fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: '15px', mb: '-15px', textAlign: 'center' }}>
                                        {errorMsg && errorMsg?.type === "phone" ? errorMsg.msg : null }
                                    </Typography>
                                </Box>
                                <Button variant="contained" 
                                    disabled={!isValidValue(first) || !isValidValue(last) || !isValidValue(email) || !isValidValue(phone)}
                                    onClick={() => handleContinue()} 
                                    sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)',
                                        color: 'white',
                                        width: '100%',
                                        height: '48px',
                                        '&:hover': {
                                            bgcolor: 'rgba(168, 0, 0, 1)',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        '&.Mui-disabled': {
                                            bgcolor: 'rgba(168, 0, 0, 0.8)',
                                            color: 'white',
                                            cursor: 'not-allowed'
                                        },
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 700,
                                        fontSize: {xs:'14px', sm:'16px'},
                                        borderRadius: '8px',
                                        boxSizing: 'border-box',
                                        mb: '12px',
                                        mt: '28px'
                                    }}
                                >
                                    Continue
                                </Button>
                            </DialogContent>
                        :
                            null
                        }
                        {phase === 1 ?
                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: {xs:'240px', sm: '500px'}, boxSizing: 'border-box', px: {xs:'16px', sm:'64px'}, gap: '12px' }}>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'18px', sm:'20px'}, color: 'white', alignSelf: 'center', lineHeight: 1.2, textAlign: 'center' }}>
                                    Dots Phone Number Verification
                                </Typography>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'12px', sm:'14px'}, color: 'white', alignSelf: 'center', textAlign: 'center', lineHeight: 1.2 }}>
                                    Please input the 6 digit verification code texted to your phone.
                                </Typography>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '6px', boxSizing: 'border-box' }}>
                                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
                                        Verification Code
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: 2,
                                        borderColor: errorMsg?.type === 'code' ? '#d32f2f' : 'rgba(255, 255, 255, 0.2)',
                                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                                        borderRadius: '8px',
                                        textAlign: 'left',
                                        height: '52px',
                                        padding: '0 10px', 
                                        width: '100%',
                                        boxSizing: 'border-box'
                                    }}>
                                        <Input
                                            disableUnderline 
                                            fullWidth 
                                            value={code}
                                            onChange={handleCodeChange}
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                color: 'white',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: 'gray',
                                                },
                                            }} 
                                        />
                                    </Box>
                                    <Typography sx={{ color: '#d32f2f', fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: 'auto', mb: '-15px', textAlign: 'center' }}>
                                        {errorMsg && errorMsg?.type === "code" ? errorMsg.msg : null }
                                    </Typography>
                                </Box>
                                {!resentCode ?
                                    <Typography onClick={() => handleVerificationCodeResend()} sx={{ 
                                        fontFamily: 'Plus Jakarta Sans', 
                                        fontWeight: 400, 
                                        fontSize: {xs:'13px', sm:'15px'}, 
                                        color: 'lightgray', 
                                        alignSelf: 'center', 
                                        textAlign: 'center', 
                                        lineHeight: 1.2, 
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: 'white',
                                        },
                                        mt: '6px'
                                    }}>
                                        Did not get a verification code?
                                    </Typography>
                                :
                                    <Typography sx={{ 
                                        fontFamily: 'Plus Jakarta Sans', 
                                        fontWeight: 400, 
                                        fontSize: {xs:'13px', sm:'15px'}, 
                                        color: 'white', 
                                        alignSelf: 'center', 
                                        textAlign: 'center', 
                                        lineHeight: 1.2, 
                                        mt: '6px'
                                    }}>
                                        New code sent to {maskPhoneNumber(phone)}
                                    </Typography>
                                }
                                <Button variant="contained" 
                                    disabled={!isValidValue(code) || code.length !== 6}
                                    onClick={() => handleVerification()} 
                                    sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)',
                                        color: 'white',
                                        width: '100%',
                                        height: '48px',
                                        '&:hover': {
                                            bgcolor: 'rgba(168, 0, 0, 1)',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        '&.Mui-disabled': {
                                            bgcolor: 'rgba(168, 0, 0, 0.8)',
                                            color: 'white',
                                            cursor: 'not-allowed'
                                        },
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 700,
                                        fontSize: {xs:'14px', sm:'16px'},
                                        borderRadius: '8px',
                                        boxSizing: 'border-box',
                                        mb: '12px',
                                        mt: '28px'
                                    }}
                                >
                                    Continue
                                </Button>
                            </DialogContent>
                        :
                            null
                        }
                        {phase === 2 && waitingPhase === 0 ?
                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: {xs:'240px', sm: '500px'}, boxSizing: 'border-box', px: {xs:'16px', sm:'64px'}, gap: '12px' }}>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'16px', sm:'20px'}, color: 'white', alignSelf: 'center', lineHeight: 1.2, textAlign: 'center' }}>
                                    1. Convert Winnings to Withdrawable Balance
                                </Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 200, fontSize: {xs:'12px', sm:'13px'}, color: 'whitesmoke', alignSelf: 'center', textAlign: 'center', lineHeight: 1.1, letterSpacing: 0 }}>
                                    Converting winnings to withdrawable balance requires admin approval. This may take 5-7 days, but is much quicker on small withdrawals. Up to $600 per week can be withdrawn. To withdraw more than $600, please reach out to support.
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#23292C', borderRadius: '8px', width: '60%', maxWidth: {xs:'none', md:'270px'}, height: {xs:'58px' , md:'64px'}, border: '1px solid rgba(255, 255, 255, 0.1)', alignItems: 'center' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: {xs:'14px' , md:'16px'}, color: 'white' }} >
                                            WINNINGS BALANCE
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: {xs:'18px' , md:'22px'}, color: '#267800' }} >
                                            ${winningsWalletBalance.toFixed(2)}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ maxWidth: {xs:'none', md:'270px'}, display: 'flex', alignSelf: 'center', width: {xs:'100%', md:'90%'}, boxSizing: 'border-box' }}>
                                    <AmountInput2 amount={amount} setAmount={setAmount} />
                                </Box>
                                <Typography sx={{ color: '#d32f2f', fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: 'auto', mb: '-15px', textAlign: 'center' }}>
                                    {errorMsg && errorMsg?.type === "requesting" ? errorMsg.msg : null }
                                </Typography>
                                <Button variant="contained" 
                                    onClick={() => handleWithdrawRequest()} 
                                    sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                        color: 'white',
                                        width: '100%',
                                        height: '48px',
                                        '&:hover': {
                                            bgcolor: 'rgba(168, 0, 0, 1)',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        '&.Mui-disabled': {
                                            bgcolor: 'rgba(168, 0, 0, 0.8)',
                                            color: 'white',
                                            cursor: 'not-allowed'
                                        },
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 700,
                                        fontSize: {xs:'14px', sm:'16px'},
                                        borderRadius: '8px',
                                        boxSizing: 'border-box',
                                        mb: {xs:'6px', sm:'12px'},
                                        mt: '10px',
                                        lineHeight: 1.2
                                    }}
                                >
                                    Request Conversion
                                </Button>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'18px', sm:'22px'}, color: 'white', alignSelf: 'center', lineHeight: 1.2, mb: {xs:'6px', sm:'12px'} }}>
                                    THEN
                                </Typography>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'16px', sm:'20px'}, color: 'white', alignSelf: 'center', lineHeight: 1.2, textAlign: 'center' }}>
                                    2. Withdraw from Withdrawable Balance
                                </Typography>
                                <Button variant="contained" 
                                    onClick={() => handleWithdraw()} 
                                    sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                        color: 'white',
                                        width: '100%',
                                        height: '48px',
                                        '&:hover': {
                                            bgcolor: 'rgba(168, 0, 0, 1)',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        '&.Mui-disabled': {
                                            bgcolor: 'rgba(168, 0, 0, 0.8)',
                                            color: 'white',
                                            cursor: 'not-allowed'
                                        },
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 700,
                                        fontSize: {xs:'14px', sm:'16px'},
                                        borderRadius: '8px',
                                        boxSizing: 'border-box',
                                        mb: '12px',
                                        mt: '10px',
                                        lineHeight: 1.2
                                    }}
                                >
                                    Withdraw
                                </Button>
                            </DialogContent>
                        :
                            null
                        }
                        {phase === 2 && waitingPhase === 1 ?
                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: {xs:'240px', sm: '500px'}, boxSizing: 'border-box', px: {xs:'16px', sm:'64px'}, gap: '12px' }}>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'16px', sm:'20px'}, color: 'white', alignSelf: 'center', lineHeight: 1.2, textAlign: 'center' }}>
                                    1. Convert Winnings to Withdrawable Balance
                                </Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 200, fontSize: {xs:'12px', sm:'13px'}, color: 'whitesmoke', alignSelf: 'center', textAlign: 'center', lineHeight: 1.1, letterSpacing: 0 }}>
                                    Please check back here soon. It may take 3 days for your withdraw request to be approved. However, usually is done within 5 minutes to 2 hours.
                                </Typography>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: {xs:'17px', sm:'22px'}, color: '#267800', alignSelf: 'center', lineHeight: 1.2, textAlign: 'center' }}>
                                    Admin Approval Pending
                                </Typography>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'18px', sm:'22px'}, color: 'white', alignSelf: 'center', lineHeight: 1.2, mb: {xs:'6px', sm:'12px'} }}>
                                    THEN
                                </Typography>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 400, fontSize: {xs:'16px', sm:'20px'}, color: 'white', alignSelf: 'center', lineHeight: 1.2, textAlign: 'center' }}>
                                    2. Withdraw from Withdrawable Balance
                                </Typography>
                                <Typography sx={{ color: '#d32f2f', fontFamily: 'Plus Jakarta Sans', fontWeight: 500, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: 'auto', mb: '-15px', textAlign: 'center' }}>
                                    {errorMsg && errorMsg?.type === "requesting" ? errorMsg.msg : null }
                                </Typography>
                                <Button variant="contained" 
                                    onClick={() => handleWithdraw()} 
                                    sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)',
                                        color: 'white',
                                        width: '100%',
                                        height: '48px',
                                        '&:hover': {
                                            bgcolor: 'rgba(168, 0, 0, 1)',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        '&.Mui-disabled': {
                                            bgcolor: 'rgba(168, 0, 0, 0.8)',
                                            color: 'white',
                                            cursor: 'not-allowed'
                                        },
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 700,
                                        fontSize: {xs:'14px', sm:'16px'},
                                        borderRadius: '8px',
                                        boxSizing: 'border-box',
                                        mb: '12px',
                                    }}
                                >
                                    Withdraw
                                </Button>
                            </DialogContent>
                        :
                            null
                        }
                        {phase === 2 && (waitingPhase === 2 || waitingPhase === -1) ?
                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: {xs:'240px', sm: '500px'}, boxSizing: 'border-box', px: {xs:'16px', sm:'64px'}, gap: '12px' }}>
                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700, fontSize: {xs:'20px', sm:'22px'}, textShadow: '1px 1px 1px black', color: waitingPhase === 2 ? '#267800' : '#A80000', alignSelf: 'center', lineHeight: 1.2, textAlign: 'center', letterSpacing: 1 }}>
                                    WITHDRAW REQUEST {waitingPhase === 2 ? 'APPROVED' : 'DENIED'}
                                </Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 200, fontSize: {xs:'12px', sm:'13px'}, color: 'whitesmoke', alignSelf: 'center', textAlign: 'center', lineHeight: 1.1, letterSpacing: 0 }}>
                                    {waitingPhase === 2 ? `Click 'WITHDRAW' below to finalize your withdraw` : 'Please submit another withdraw request or contact AgentPicks support if you believe this was a mistake'}
                                </Typography>
                                <Button variant="contained" 
                                    onClick={() => handleAdminResponse()} 
                                    sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)',
                                        color: 'white',
                                        width: '100%',
                                        height: '48px',
                                        '&:hover': {
                                            bgcolor: 'rgba(168, 0, 0, 1)',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        '&.Mui-disabled': {
                                            bgcolor: 'rgba(168, 0, 0, 0.8)',
                                            color: 'white',
                                            cursor: 'not-allowed'
                                        },
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 700,
                                        fontSize: {xs:'14px', sm:'16px'},
                                        borderRadius: '8px',
                                        boxSizing: 'border-box',
                                        mb: '12px',
                                        mt: '28px'
                                    }}
                                >
                                    {waitingPhase === 2 ? 'Withdraw' : 'Continue'}
                                </Button>
                            </DialogContent>
                        :
                            null
                        }
                        {phase === 3 ?
                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', minWidth: {xs:'240px', sm: '500px'}, minHeight: {xs:'240px', sm: '500px'}, boxSizing: 'border-box', px: '16px' }}>
                                <iframe
                                    src={link}
                                    width="100%"
                                    height="600px"
                                    style={{ border: 'none' }}
                                    title="Form"
                                ></iframe>
                            </DialogContent>
                        :
                            null
                        }
                    </>
                }
            </Dialog>
        </ThemeProvider>
    )
}

export default WithdrawDialog