import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { MdAccountCircle } from "react-icons/md";

import { supabase } from '../auth/client';
import NavBtn from './NavBtn';
import { getUserInfoForWithdraw } from '../APIs';

const settings = ['Settings', 'Logout'];

function NavBar2(props) {

    const [first, setFirst] = useState("")
    const [last, setLast] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    const logout = async () => {
        props.setSession(null)
        const { error } = await supabase.auth.signOut()
        navigate('/login')
    }


    const navigate = useNavigate()
    let location = useLocation().pathname.substring(1)

    if (location.endsWith('/')) {
        location = location.slice(0, -1);
    }

    const locationFormatter = { '': 'Board', 'board': 'Board', 'myentries': 'My Entries', 'promotions': 'Promotions', 'How To Play': 'howtoplay', 'support': 'Support', 'login': 'Login', 'settings': 'Settings'}

    let curr = locationFormatter[location] ?? ''
    curr = curr.toUpperCase() ?? ''

    const fetchInfo = async () => {
        setIsLoading(true)
        const response = await getUserInfoForWithdraw({token: props.session.access_token})
        
        if (response.status === 401) {
            props.setSession(null)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            setIsLoading(false)
            return
        }

        setFirst(response?.info?.firstName)
        setLast(response?.info?.lastName)
        setIsLoading(false)
        return
    }

    useEffect(() => {
        if (props.session) {
            fetchInfo()
        }
    }, [props.session]);

    return (
        <Box sx={{ display: { xs: 'none', lg: 'flex' }, boxSizing: 'border-box', 
            justifyContent: 'center', px: '20px', 
            width: {md:'280px', lg:'300px', xl:'320px'}, 
            minWidth: '280px', maxWidth: '320px', bgcolor: '#0B1215'
        }}>
            <Box sx={{ bgcolor: '#171E21', borderRadius: '12px', 
                height: '96vh', boxSizing: 'border-box', 
                width: '100%', pt: '20px', pb: '36px', 
                px: '12px', flexDirection: 'column', 
                justifyContent: 'space-between', display: 'flex',
                position: 'sticky', top: '2vh', zIndex: 1000,
                border: '1px solid rgba(255, 255, 255, 0.1)'
            }}>
                <Box sx={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '64px', justifyContent: 'center', mb: '48px' }}>
                        {/*<img src="/newlogo.svg" height="48px" alt="Logo" /> */}
                        <Box onClick={() => navigate('/')} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', ml: '-4px' }}>
                            <img src='/logo.svg' height={'44px'}/>
                            <h1 style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '28px', color: 'white' }} >AGENTPICKS</h1>
                        </Box>
                    </Box>
                    <NavBtn page={'BOARD'} curr={curr} to={'/board'} />
                    <NavBtn page={'MY ENTRIES'} curr={curr} to={'/myentries'} />
                    <NavBtn page={'PROMOTIONS'} curr={curr} to={'/promotions'} />
                    <NavBtn page={'SETTINGS'} curr={curr} to={'/settings'} />
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        flexDirection: 'row',
                        boxSizing: 'border-box',
                    }}
                >
                    {props.session ? 
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
                                <Box sx={{ 
                                    color: 'rgba(168, 0, 0, 0.8)', 
                                    '&:hover': {
                                        color: 'rgba(168, 0, 0, 1)',
                                    },
                                    width: '40px', 
                                    height: '40px',
                                }}>
                                    <MdAccountCircle size={'40px'} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <Typography sx={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins', lineHeight: 1.1 }}>
                                        {first} {last}
                                    </Typography>
                                    <Typography sx={{ color: 'gray', fontSize: '11px', fontFamily: 'Poppins', lineHeight: 1.1  }}>
                                        {props.session?.user?.email}
                                    </Typography>
                                </Box>
                            </Box>
                            <Button variant="contained" onClick={() => logout()} sx={{ 
                                bgcolor: '#23292C', 
                                color: 'white',
                                width: '100%',
                                height: '40px',
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '14px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Logout
                            </Button>
                        </Box>
                    :
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', boxSizing: 'border-box', gap: '16px' }}>
                            <Button variant="contained" onClick={() => navigate('/login')} sx={{ 
                                bgcolor: '#23292C', 
                                color: 'white',
                                width: '100%',
                                height: '40px',
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '14px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Log In
                            </Button>
                            <Button variant="contained" onClick={() => window.open("https://esportsagent.gg/signup", "_blank")} sx={{ 
                                bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                color: 'white',
                                width: '100%',
                                height: '40px',
                                '&:hover': {
                                    bgcolor: '#23292C',
                                    boxShadow: 'inset 0px 0px 16px rgba(168, 0, 0, 0.5)',
                                    color: 'rgba(168, 0, 0, 1)',
                                    border: '0.5px solid rgba(168, 0, 0, 0.5)'
                                },  
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '14px',
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                lineHeight: 1,
                                textTransform: 'none',
                                boxShadow: 'none',
                                border: '0.5px solid #23292C',
                            }}>
                                Sign Up
                            </Button>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}
export default NavBar2;