import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

import { adminEditCC, adminGetCCs } from '../APIs';
import AdminCCDialog from './AdminCCDialog';
import AdminAreYouSureDialog from './AdminAreYouSureDialog';

import { supabase } from '../auth/client';

function AdminCCDashboard(props) {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const [creatorCodeData, setCreatorCodeData] = useState(null);

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const [errorMsg, setErrorMsg] = useState({})

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [confirmUpdate, setConfirmUpdate] = useState(null);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });

    const fetchCreatorCodeData = async () => {

        setIsLoading(true)

        const userData = {
            token: props.adminSession.access_token
        }

        const data = await adminGetCCs(userData)
        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (data.status !== 200) {
          return
        }
        setCreatorCodeData(data.creatorCodes)
        setIsLoading(false)
        return
    }

    const rows = creatorCodeData

    const columns = [
        {   
            field: '_id', 
            headerName: 'id', 
            width: 240,
        },
        {
            field: 'code',
            headerName: 'code',
            width: 120,
            editable: false,
        },
        {
            field: 'active',
            headerName: 'ACTIVE',
            width: 120,
            editable: true,
            type: 'singleSelect',
            valueOptions: [false, true]
        },
        {
            field: 'activeUserCount',
            headerName: 'activeUserCount',
            width: 120,
            editable: false,
            type: 'number'
        },
        {
            field: 'entryCount',
            headerName: 'entryCount',
            width: 120,
            editable: false,
            type: 'number'
        },
        {
            field: 'totalBoosted',
            headerName: 'totalBoosted',
            width: 120,
            editable: false,
            type: 'number'
        },
        {
            field: 'totalWagered',
            headerName: 'totalWagered',
            width: 120,
            editable: false,
            type: 'number'
        },
        {
            field: 'matching',
            headerName: 'MATCHING',
            width: 120,
            editable: true,
            type: 'number'
        },
        {
            field: 'matched',
            headerName: 'matched',
            width: 120,
            editable: false,
            type: 'number'
        },
    ];

    function isValidString(input, minLength, maxLength) {
        return typeof input === 'string' && input.length >= minLength && input.length <= maxLength;
    }
    
    function isValidBoolean(input) {
        return typeof input === 'boolean';
    }

    // Check if value is empty, null, undefined, or NaN
    function isValidValue(value) {
        return value !== null && value !== undefined && value !== '' && !Number.isNaN(value);
    }

    function isValidNumber(value) {
        const numberValue = Number(value);
        return Number.isInteger(numberValue) && numberValue >= 0;
    }

    function isValidCreatorCode(creatorCode) {
        // Regular expression that matches strings of length 2-9 containing only alphanumeric characters
        const regex = /^[a-zA-Z0-9]{2,9}$/;
        return regex.test(creatorCode);
    }

    function verifyData(userData) {
    
        if (!isValidValue(userData.code) || !isValidString(userData.code, 1, 9) || !isValidCreatorCode(userData.code)) {
            setErrorMsg({type: "code", msg: "Invalid creator code format. Must be alphanumeric and 2-9 characters"});
            return false;
        }
        if (!isValidValue(userData.active) || !isValidBoolean(userData.active)) {
            setErrorMsg({type: "active", msg: "Invalid active value. Must be true or false"});
            return false;
        }
        if (!isValidValue(userData.matching) || !isValidNumber(userData.matching)) {
            setErrorMsg({type: "matching", msg: "Invalid matched deposit limit. Must be a whole number >= 0"});
            return false;
        }

        // If all checks pass, clear any previous error message
        setErrorMsg({});
        return true;
    }

    const processRowUpdate = async (newRow, oldRow) => {
        const updatedRow = { ...newRow, isNew: false };

        const userData = {
            ...updatedRow,
            token: props.adminSession.access_token
        }

        if (!verifyData(userData)) {
            return
        } 

        setOpen2(true)

        try {
            // Wait for the user to confirm the action
            await new Promise((resolve, reject) => {
                setConfirmUpdate({ resolve, reject })
            }) 

            // if promise was resolved
            // Close the dialog
            setIsLoading(true)
            setOpen2(false);
            setErrorMsg({})

            const response = await adminEditCC(userData)
            if (response.status === 401) {
                props.setAdminSession(null)
                const { error } = await supabase.auth.signOut()
                return
            } else if (response.status !== 200) {
                setErrorMsg({type: 'edit', msg: 'Edit failed in database'})
                setIsLoading(false)
                return oldRow
            }
            setIsLoading(false)
            setUpdate(!update)
            return updatedRow

        } catch {
            // if promise was rejected
            setOpen2(false)
            setErrorMsg({})
            return oldRow
        }
    };

    const handleConfirm = () => {
        // Resolve the promise
        if (confirmUpdate) {
            confirmUpdate.resolve();
            setConfirmUpdate(null);
        }

    };

    const handleClose = () => {
        // Reject the promise
        if (confirmUpdate) {
            confirmUpdate.reject();
            setConfirmUpdate(null);
        }
    };

    useEffect(() => {
        fetchCreatorCodeData()
    }, [update])

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0) {
          const timer = setTimeout(() => {
            setErrorMsg({})
          }, 5000)

          return () => clearTimeout(timer);
        }
    }, [errorMsg])
        
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'start', boxSizing: 'border-box', flexDirection: 'column' }}>
            {errorMsg && errorMsg.msg ? 
                <Alert sx={{ 
                    position: 'fixed', top: {xs: '60px', md: '100px'}, width: {xs:'80%', md:'auto'}, 
                    zIndex: 997, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', boxSizing: 'border-box', flexDirection: 'row', mt: '32px' }}>
                <Button variant="contained" onClick={() => setOpen(true)} sx={{ 
                    bgcolor: 'rgba(168, 0, 0, 0.8)', 
                    color: 'white',
                    width: '260px',
                    height: '48px',
                    '&:hover': {
                        backgroundColor: '#A80000',
                    },
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: 500,
                    fontSize: '13px',
                    borderRadius: '8px',
                    boxSizing: 'border-box'
                }}>
                    Create New Creator Code
                </Button>
            </Box>
            <AdminCCDialog open={open} setOpen={setOpen} update={update} setUpdate={setUpdate} adminSession={props.adminSession} setAdminSession={props.setAdminSession} />
            <AdminAreYouSureDialog 
                open={open2}
                func={handleConfirm} 
                closeFunc={handleClose}
                funcString={"Save Edit"} 
                funcDesc={"Are you sure you want to edit this creator code? Deactivating a creator code will cause all users with that active code to lose the creator code (+ they won't be notified). This cannot be reverted."} 
            />
            {!isLoading ? 
                <ThemeProvider theme={darkTheme}>
                    {creatorCodeData ? 
                        <Box sx={{ display: 'flex', width: '80%', gap: '24px', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', mt: '16px' }}>
                            <Box sx={{ height: 631, width: '100%', bgcolor: '#191919' }}>
                                <DataGrid
                                    sx={{ color: 'white', 
                                        '& .MuiDataGrid-filler': {
                                            backgroundColor: '#191919',
                                        }, 
                                        '& .MuiDataGrid-scrollbar--horizontal': {
                                            left: 0
                                        },
                                        '--DataGrid-containerBackground': '#191919'
                                    }}
                                    rows={rows}
                                    getRowId={(row) => row._id}
                                    columns={columns}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    processRowUpdate={processRowUpdate}
                                    editMode='row'
                                />
                            </Box>
                        </Box>
                    :
                        null
                    }
                </ThemeProvider>
            :
                <Skeleton variant="rounded"  width='80%' height='75%' sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', alignSelf: 'center', mt: '16px' }} />
            }
        </Box>
    )
}

export default AdminCCDashboard