import { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

import { FaCheck, FaTimes } from "react-icons/fa";

import { adminGetWithdrawRequests, adminGetWithdraws, adminRespondToWithdrawRequest } from '../APIs';

import { supabase } from '../auth/client';
import AdminAreYouSureDialog from './AdminAreYouSureDialog';

function AdminWithdrawsDashboard(props) {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    const [withdrawsData, setWithdrawsData] = useState(null)

    // used for skeleton components when fetching data from backend
    const [isLoading, setIsLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [errorMsg, setErrorMsg] = useState({})

    const [openApprove, setOpenApprove] = useState(false)
    const [openDeny, setOpenDeny] = useState(false)
    const [id, setID] = useState("")

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });

    const fetchWithdrawsData = async () => {
        setIsLoading(true)
        const userData = {
            token: props.adminSession.access_token
        }
        const data = await adminGetWithdrawRequests(userData)
        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (data.status !== 200) {
          return
        }
        setWithdrawsData(data.withdraws)
        setIsLoading(false)
        return
    }

    const fetchWithdrawsData2 = async () => {
        setIsLoading(true)
        const userData = {
            token: props.adminSession.access_token
        }
        const data = await adminGetWithdraws(userData)
        if (data.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (data.status !== 200) {
          return
        }
        setWithdrawsData(data.withdraws)
        setIsLoading(false)
        return
    }

    const rows = withdrawsData

    const baseColumns = [
        {
            field: '_id', 
            headerName: 'id', 
            width: 140,
        },
        {
            field: 'userID',
            headerName: 'userID',
            width: 140,
            editable: false,
        },
        {
            field: 'dotsID',
            headerName: 'dotsID',
            width: 140,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'email',
            width: 200,
            editable: false,
        },
        {
            field: 'firstName',
            headerName: 'firstName',
            width: 160,
            editable: false,
        },
        {
            field: 'lastName',
            headerName: 'lastName',
            width: 160,
            editable: false,
        },
        {
            field: 'amount',
            headerName: 'amount',
            width: 100,
            type: 'number',
            editable: false,
        },
        {
            field: 'status',
            headerName: 'status',
            width: 60,
            type: 'number',
            editable: false,
        },
        {
            field: 'time',
            headerName: 'time',
            width: 200,
            editable: false,
        },
    ];
    
    const actionsColumn = {
        field: 'actions',
        type: 'actions',
        headerName: 'Approve / Deny',
        width: 200,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            return [
                <GridActionsCellItem
                    icon={<FaCheck color='#2e7d32' />}
                    label="Approve"
                    onClick={() => handleApproveClick(id)}
                    color="inherit"
                    sx={{ mr: '24px' }}
                />,
                <GridActionsCellItem
                    icon={<FaTimes color='#d32f2f' />}
                    label="Deny"
                    onClick={() => handleDenyClick(id)}
                    color="inherit"
                />,
            ];
        },
    };
    
    // Conditionally add the actions column if props.tab === 6
    const columns = props.tab === 6 ? [...baseColumns, actionsColumn] : baseColumns;

    const handleApproveClick = async (id) => {
        setOpenApprove(true)
        setID(id)
        return
    }

    const handleDenyClick = async (id) => {
        setOpenDeny(true)
        setID(id)
        return
    }

    const handleWithdrawRequest = async (adminResponse) => {
        setIsLoading(true)
        const userData = {
            id: id,
            adminResponse: adminResponse,
            token: props.adminSession.access_token
        }
        const response = await adminRespondToWithdrawRequest(userData)
        if (response.status === 401) {
            props.setAdminSession(null)
            const { error } = await supabase.auth.signOut()
            return
        } else if (response.status === 429) {
            setErrorMsg({type: "other", msg: "Too many requests. Please try again in a few minutes"})
            setUpdate(!update)
            setID("")
            setOpenApprove(false)
            setOpenDeny(false)
            setIsLoading(false)
            return
        } else if (response.status !== 200) {
            const errorMsg = response?.errorObj?.msg ? response.errorObj.msg : 'Server Error';
            setErrorMsg({ type: 'other', msg: errorMsg });
            setUpdate(!update)
            setID("")
            setOpenApprove(false)
            setOpenDeny(false)
            setIsLoading(false)
            return
        }
        setUpdate(!update)
        setID("")
        setErrorMsg({})
        setOpenApprove(false)
        setOpenDeny(false)
        setIsLoading(false)
    }


    const handleCloseApprove = () => {
        setOpenApprove(false)
        setID("")
        setErrorMsg({})
    }

    const handleCloseDeny = () => {
        setOpenDeny(false)
        setID("")
        setErrorMsg({})
    }
    
    useEffect(() => {
        if (props.tab === 6) {
            fetchWithdrawsData()
        }
        if (props.tab === 7) {
            fetchWithdrawsData2()
        }
    }, [props.tab, update])

    useEffect(() => {
        if (Object.keys(errorMsg).length > 0) {
          const timer = setTimeout(() => {
            setErrorMsg({})
          }, 5000)
          
          return () => clearTimeout(timer);
        }
    }, [errorMsg])

        
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'start', boxSizing: 'border-box', flexDirection: 'column' }}>
            {errorMsg && errorMsg.msg ? 
                <Alert sx={{ 
                    position: 'fixed', top: {xs: '60px', md: '100px'}, width: {xs:'80%', md:'auto'}, 
                    zIndex: 997, bgcolor: '#191919', left: '50%', transform: 'translateX(-50%)',
                    border: '2px solid #d32f2f', color: 'white', 
                    fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
                    alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
                }} severity="error" >
                    {errorMsg?.msg}
                </Alert> 
            : null }
            <AdminAreYouSureDialog 
                open={openApprove}
                func={() => handleWithdrawRequest(1)} 
                closeFunc={handleCloseApprove}
                funcString={"Approve"} 
                funcDesc={"Are you sure you want to approve this withdraw?"} 
            />
            <AdminAreYouSureDialog 
                open={openDeny}
                func={() => handleWithdrawRequest(-1)} 
                closeFunc={handleCloseDeny}
                funcString={"Deny"} 
                funcDesc={"Are you sure you want to deny this withdraw?"} 
            />
            {!isLoading ? 
                <>
                    <ThemeProvider theme={darkTheme}>
                        {withdrawsData ? 
                            <Box sx={{ display: 'flex', width: '80%', gap: '24px', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', mt: '16px' }}>
                                <Box sx={{ height: 631, width: '100%', bgcolor: '#191919' }}>
                                    <DataGrid
                                        sx={{ color: 'white', 
                                            '& .MuiDataGrid-filler': {
                                                backgroundColor: '#191919',
                                            }, 
                                            '& .MuiDataGrid-scrollbar--horizontal': {
                                                left: 0
                                            },
                                            '--DataGrid-containerBackground': '#191919'
                                        }}
                                        rows={rows}
                                        getRowId={(row) => row._id}
                                        columns={columns}
                                        paginationModel={paginationModel}
                                        onPaginationModelChange={setPaginationModel}
                                        editMode='row'
                                    />
                                </Box>
                            </Box>
                        :
                            null
                        }
                    </ThemeProvider>
                </>
            : 
                <Skeleton variant="rounded"  width='80%' height='75%' sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', alignSelf: 'center', mt: '16px' }} />
            }
        </Box>
    )
}

export default AdminWithdrawsDashboard