
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function AdminAreYouSureDialog(props) {

    const handleSubmit = () => {
        props.func()
    }
    
    const handleClose = () => {
        props.closeFunc()
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            sx={{ zIndex: 996 }}
        >
            <DialogTitle>Are You Sure?</DialogTitle>
            <DialogContent sx={{ minWidth: '600px', boxSizing: 'border-box' }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '36px', mt: '10px' }}>
                    <DialogContentText>
                        {props.funcDesc}
                    </DialogContentText>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>{props.funcString}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AdminAreYouSureDialog